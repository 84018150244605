<!-- 酒店管理-套票售票 -->

<template>
  <div class="order-assoc main-cnt">
    <div class="title">
      售票列表
    </div>

    <div class="content">
      <common-table ref="tableRef" tableHeight="calc(100vh - 320px)" :needExport="true" :needPrint="true"
        :filters="filters" :apiName="HotelApi.getAssocSalesList" :columns="tableColumns" @hotelChange="hotelChange"
        @scenicSpotChange="scenicSpotChange" @storeChange="storeChange" @dinningChange="dinningChange"
        @export="handleExport" @print="handlePrint" @onOrderListBtn="onOrderListBtn"
        @onIncludProjects="onIncludProjects" @saleTickets="saleTickets" @statusChange="statusChange">
        <!-- 团体售票 -->
        <template #groupSaleTickets="{ row }">
          <template
            v-if="row.order_type == '2' && (row.type_id == '1' || row.type_id == '3') && authData.indexOf('h_0vkXkF2Gr85AnIMDtS7de6mvb68P') != -1">
            <el-button class="theme-font-btn" @click="groupSaleTickets(row)">团体售票</el-button>
          </template>
        </template>
      </common-table>
    </div>

    <!-- 售票入口弹框 -->
    <w-dialog ref="ticketDialog" class="ticket-dialog" :title="ticketType == '1' ? '售票信息' : '团体售票'" width="60%"
      btnWidth="140px" top="4vh" confirmText="确认售票" @wConfirm="confirm">
      <div class="ticket-content">
        <div class="left-content">
          <div class="basic-info">
            <div class="ticket-title">{{ ticketInfo?.st_name }}</div>
            <div class="basic-item">
              <span>包含项目</span> {{ ticketInfo?.s_name }}
            </div>
            <div class="basic-item">
              <span>售票单价</span> ￥{{ ticketInfo?.st_price }}
            </div>
          </div>
          <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top" class="ticket-form">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-form-item prop="num" label="售票数量">
                  <el-input v-model="formData.num" autocomplete="off" placeholder="请输入售票数量" clearable type="number"
                    min="1" @blur="onInputBlur" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item prop="timeArr" label="使用期限">
                  <el-date-picker v-model="formData.timeArr" type="daterange" range-separator="至"
                    start-placeholder="开始时间" end-placeholder="结束时间" value-format="YYYY-MM-DD"
                    :disabled-date="disabledDate" :disabled="ticketInfo.st_sttid == 2 ? true : false">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="ticketType == 2">
              <el-col :span="12">
                <el-form-item prop="yh_money" label="优惠金额">
                  <el-input v-model="formData.yh_money" autocomplete="off" placeholder="请输入优惠金额" clearable type="number"
                    min="0" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="ticketType == 1">
              <el-col :span="12">
                <el-form-item prop="coupon_money" label="代金券抵扣金额">
                  <el-input v-model="formData.coupon_money" autocomplete="off" placeholder="请输入代金券抵扣金额" clearable
                    type="number" min="0" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <el-form-item prop="remark" label="备注">
                  <el-input v-model="formData.remark" placeholder="请输入备注" :rows="3" type="textarea" />
                </el-form-item>
              </el-col>
            </el-row>
            <div class="total-money">
              合计金额<span>￥{{ totalMoney() }}</span>
            </div>
            <el-divider></el-divider>
            <el-row>
              <el-col :span="24">
                <el-form-item label="支付方式" prop="payway">
                  <el-radio-group v-model="formData.payway" @change="changeRadio">
                    <template v-for="(el, index) in orderTypeOptions" :key="index">
                      <el-radio :label="el.orp_id">{{ el.orp_name }}</el-radio>
                    </template>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="formData.payway == 7" :gutter="20">
              <el-col :span="12">
                <el-form-item label="AR账户" prop="ar_id">
                  <el-select v-model="formData.ar_id" placeholder="请选择AR账户" filterable>
                    <el-option :label="item.name" :value="item.id" v-for="item in companyOptions" :key="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="游客姓名" prop="ar_tourist">
                  <el-input v-model="formData.ar_tourist" placeholder="请输入游客姓名"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="formData.payway == 7" :gutter="20">
              <el-col :span="24">
                <el-form-item label="挂账备注" prop="ar_remark">
                  <el-input v-model="formData.ar_remark" placeholder="请输入挂账备注" :rows="2" type="textarea" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="right-content" v-loading="faceLoading" element-loading-text="上传图片中">
          <div class="right-title">
            <div class="enter-title">游客信息录入</div>
            <div class="enter-switch" v-if="[1, 3].includes(ticketInfo.st_sttid) && ticketType == '1'">
              <span>使用纸质票</span>
              <el-switch v-model="ticketCode" inline-prompt active-text="是" inactive-text="否" />
            </div>
          </div>
          <el-empty v-if="!visitorList.length" description="请先在左侧选择售票数量！" />
          <template v-else>
            <div class="visitor-item" v-for="(el, index) in visitorList" :key="index">
              <span class="close-icon" @click="deleteVisitor(index)">
                <el-icon>
                  <Close />
                </el-icon>
              </span>
              <el-form label-position="right" class="visitor-form">
                <el-form-item prop="m_realname" label="游客姓名">
                  <el-input v-model="el.m_realname" autocomplete="off" :id="'visitorName' + index" placeholder="请输入游客姓名"
                    @focus="handleFocus('visitorName', index, index + 1)" />
                </el-form-item>
                <el-form-item prop="m_mobile" label="手机号码">
                  <el-input v-model="el.m_mobile" autocomplete="off" :id="'visitorPhone' + index" placeholder="请输入手机号码"
                    @focus="handleFocus('visitorPhone', index, index + 1)" />
                </el-form-item>
                <el-form-item prop="m_idcard" label="身份证号">
                  <el-input v-model="el.m_idcard" autocomplete="off" :id="'visitorCard' + index" placeholder="请输入身份证号"
                    @focus="handleFocus('visitorCard', index, index + 1)" />
                </el-form-item>
                <el-form-item prop="paper_sn" label="录入票码" v-if="[1, 3].includes(ticketInfo.st_sttid)">
                  <el-input v-model="el.paper_sn" autocomplete="off" :id="'visitorSn' + index"
                    placeholder="请用扫码枪识别票面二维码录入" @keyup.enter="handleSn('visitorName', index, index + 1)" />
                </el-form-item>
              </el-form>
              <div class="upload-box">
                <div class="img-container" v-if="[2, 4].includes(ticketInfo.st_sttid)">
                  <div v-if="el.img && el.img[0] && el.img[0].file_url" class="face-img">
                    <el-image style="width: 100px; height: 100px" :src="el.img[0].file_url"
                      :preview-src-list="[el.img[0].file_url]" fit="cover">
                    </el-image>
                    <span class="del" @click="deleteFile('', index)">
                      <el-icon>
                        <Close />
                      </el-icon>
                    </span>
                  </div>
                  <el-dropdown v-else>
                    <div class="into-face">录入人脸</div>
                    <template #dropdown>
                      <el-dropdown-menu class="face-dropdown-menu">
                        <el-dropdown-item class="face-dropdown-item">
                          <img-upload uploadTitle="人脸照片" :limit="1" uploadText="本地上传"
                            @uploadFile="uploadFile($event, index)" @deleteFile="deleteFile($event, index)"
                            @uploadLoading="uploadLoading"></img-upload>
                        </el-dropdown-item>
                        <el-dropdown-item @click="openFaceDialog(index)" class="face-dropdown-item">拍照录入
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </w-dialog>


    <StatusDialog ref="loadingDialog" :type="1" :title="lastPayway ? '等待支付中' : '确认订单中'" :btnWidth="'200px'"
      @lConfirm="CloseLoadingDialog">
      <div class="status-tips">
        <div v-if="lastPayway">
          请提醒游客使用 <span>{{ lastPayway }}</span> 支付订单
        </div>
        <el-input v-if="lastPayway && lastPayway != '现金'" class="barcode-input" v-model="barCodeInput"
          ref="barCodeInputRef" @keyup.enter="barCodeInfo" placeholder="请将光标聚焦在输入框内扫码收款"></el-input>
      </div>
    </StatusDialog>

    <StatusDialog ref="succesDialog" :type="2" title="收费成功" @lConfirm="CloseStatusDialog" @rConfirm="CloseStatusDialog">
    </StatusDialog>

    <StatusDialog ref="errorDialog" :type="3" :title="errorTitle" cancelText="编辑订单" confirmText="重新发起"
      @lConfirm="CloseErrorDialog" @rConfirm="confirm">
    </StatusDialog>

    <OrderList ref="orderRef" @submit="refreshTable"></OrderList>

    <!-- 包含项目 -->
    <w-dialog ref="dialogContainRef" title="套票包含" width="50%" top="10vh" :hideFooter="true">
      <common-table ref="projectTableRef" :ispaging="false" :tableData="includProjectDatas"
        :columns="projectTableColumns" tableHeight="500">
      </common-table>
    </w-dialog>
  </div>
</template>

<script setup>
  import { ref, onMounted, watch, computed, nextTick, } from "vue";
  import { BasicApi, HotelApi, ScenicApi, } from "@/plugins/api.js";
  import { ElMessage } from "element-plus";
  import { useStore } from "vuex";
  import {
    exportExcel,
    print,
  } from "@/utils/common.js";
  import StatusDialog from "@/components/status-dialog/status-dialog.vue";
  import imgUpload from "@/components/img-upload/img-upload.vue";
  import { Close } from "@element-plus/icons-vue";
  import OrderList from "../components/OrderListDialog.vue";
  import dayjs from "dayjs";
  import { checkStr } from "@/utils/common.js";

  const store = useStore();
  const menuTokens = computed(() => store.state.menuToken.menuTokens);
  const authData = ref([]);
  watch(
    () => menuTokens.value,
    (data) => {
      if (data.length) {
        authData.value = data;
      }
    },
    {
      deep: true,
      immediate: true,
    }
  );

  onMounted(() => {
    getHotelData();
    hotelChange({ value: '' });
    getAssocStatusList();
    tableRef.value.tableLoad();
    getOrderType();
  });
  const filters = ref([  // 筛选条件
    {
      filterType: "select",
      name: "h_id",
      value: "",
      placeholder: "请选择酒店",
      options: [],
      lab: "h_name",
      val: "h_id",
      action: "hotelChange",
    },
    {
      filterType: "select",
      name: "sp_id",
      value: "",
      placeholder: "请选择景点",
      options: [],
      lab: "ra_assoc_name",
      val: "ra_assoc_id",
      action: "scenicSpotChange",
    },
    {
      filterType: "select",
      name: "dr_id",
      value: "",
      placeholder: "请选择餐厅",
      options: [],
      lab: "ra_assoc_name",
      val: "ra_assoc_id",
      action: "dinningChange",
    },
    {
      filterType: "select",
      name: "pom_id",
      value: "",
      placeholder: "请选择门店",
      options: [],
      lab: "ra_assoc_name",
      val: "ra_assoc_id",
      action: "storeChange",
    },
    {
      filterType: "select",
      name: "status",
      value: 1,
      placeholder: "请选择状态",
      options: [],
      noclear: true,
      lab: "name",
      val: "id",
    },
  ]);
  const tableRef = ref(null);  // 表格对象
  const tableColumns = ref([  // 表格配置
    {
      prop: "id",
      label: "套票ID",
    },
    {
      prop: "name",
      label: "套票名称",
      minWidth: 170,
    },
    {
      type: "block",
      prop: "bind_count",
      label: "包含项目",
      minWidth: 100,
      active: "onIncludProjects",
      token: 'h_r4UxoYtMr2aIXp1as7QfzVhe3JUk',
    },
    {
      prop: "type_name",
      label: "套票类型",
    },
    {
      type: "switch",
      prop: "is_top",
      label: "是否置顶",
      activeText: "是",
      inactiveText: "否",
      token: "h_r4UxoYtMr2aIXp1as7QfzVhe3JUk",
    },
    {
      prop: "use_info",
      label: "售卖日期",
      minWidth: 140,
    },
    {
      prop: "price",
      label: "售价",
    },
    {
      prop: "all_stock",
      label: "库存",
    },
    {
      prop: "today_buyed_stock",
      label: "今日已售",
    },
    {
      prop: "buyed_stock",
      label: "累计销售",
    },
    {
      prop: "stock",
      label: "余票",
    },
    {
      type: "operation",
      prop: "",
      label: "操作",
      minWidth: 270,
      bottons: [
        {
          name: "订单列表",
          action: "onOrderListBtn",
          token: "h_llgBnEXlwvQ1tdCTCyChBzmVD3YR",
          className: "theme-font-btn",
        },
        {
          name: "售票",
          action: "saleTickets",
          token: "h_shLzGFEfse37uhGYKvTX1EReF9n2",
          className: "theme-font-btn",
          HiddenKey: "order_type",
          showValue: [2],
        },
        {
          name: "团体售票",
          action: "groupSaleTickets",
          type: "customRender",
        },
      ],
    },
  ]);

  /**
   * 
   * 重新获取列表数据
   * 
   * **/
  const refreshTable = () => {
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 获取酒店选项
   * 
   * **/
  const getHotelData = () => {
    BasicApi.getHotelByProject({ is_verify: 1 }).then((res) => {
      if (res.Code === 200) {
        filters.value[0].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 筛选条件-选择酒店
   * 
   * **/
  const hotelChange = (obj) => {
    filters.value[1].value = "";
    filters.value[2].value = "";
    filters.value[3].value = "";
    tableRef.value.tableLoad();
    HotelApi.getAssocListSelect({ h_id: obj.value }).then((res) => {
      if (res.Code === 200) {
        filters.value[1].options = res.Data.scenic_spot ? res.Data.scenic_spot : [];
        filters.value[2].options = res.Data.dinning_room ? res.Data.dinning_room : [];
        filters.value[3].options = res.Data.polymerization ? res.Data.polymerization : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 筛选条件-选择景点
   * 
   * **/
  const scenicSpotChange = () => {
    filters.value[2].value = "";
    filters.value[3].value = "";
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 筛选条件-选择餐厅
   * 
   * **/
  const dinningChange = () => {
    filters.value[1].value = "";
    filters.value[3].value = "";
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 筛选条件-选择门店
   * 
   * **/
  const storeChange = () => {
    filters.value[1].value = "";
    filters.value[2].value = "";
    tableRef.value.tableLoad();
  }
  /**
   * 
   * 筛选条件-获取状态选项
   * 
   * **/
  const getAssocStatusList = () => {
    HotelApi.getAssocStatusList().then((res) => {
      if (res.Code === 200) {
        filters.value[4].options = res.Data ? res.Data : [];
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  const dialogContainRef = ref(null);  // 包含项目对象
  const projectTableRef = ref(null);  // 包含项目表格对象
  const includProjectDatas = ref([]);  // 项目数据
  const projectTableColumns = ref([  // 包含项目表格配置
    {
      prop: "merchant_name",
      label: "商家",
      minWidth: 200,
    },
    {
      prop: "name",
      label: "名称",
      minWidth: 180,
    },
    {
      prop: "count",
      label: "数量",
    },
  ]);
  /**
   * 
   * 包含项目
   * 
   * **/
  const onIncludProjects = (row) => {
    includProjectDatas.value = row.bind_list;
    dialogContainRef.value.show();
    nextTick(() => {
      projectTableRef.value.tableLoad();
    });
  }
  /**
   * 
   * 是否置顶开关
   * 
   * **/
  const statusChange = (row) => {
    HotelApi.assocSalesTop({ top_id: row.top_id, }).then((res) => {
      if (res.Code === 200) {
        ElMessage.success('操作成功！');
        refreshTable();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  const orderRef = ref(null);  // 订单列表对象
  /**
   * 
   * 订单列表按钮
   * 
   * **/
  const onOrderListBtn = (row) => {
    orderRef.value.openDialog(row);
  }
  // 导出数据
  const propertiesConfig = ref([
    { field: "id", displayName: "套票ID" },
    { field: "name", displayName: "套票名称" },
    { field: "bind_count", displayName: "包含项目" },
    { field: "type_name", displayName: "套票类型" },
    { field: "is_top_text", displayName: "是否置顶" },
    { field: "use_info", displayName: "售卖日期" },
    { field: "price", displayName: "售价" },
    { field: "all_stock", displayName: "库存" },
    { field: "today_buyed_stock", displayName: "今日已售" },
    { field: "buyed_stock", displayName: "累计销售" },
    { field: "stock", displayName: "余票" },
  ]);
  function processData(data) {
    data.length &&
      data.forEach((el) => {
        el.is_top_text = el.is_top == '1' ? '是' : '否';
      });
    return data;
  }
  /**
   * 
   * 导出按钮
   * 
   * **/
  function handleExport(data) {
    try {
      exportExcel("售票列表", processData(data), propertiesConfig.value);
      ElMessage.success({
        message: "导出成功！",
      });
    } catch (e) {
      ElMessage.error({
        message: "导出失败！",
      });
    }
  }
  /**
   * 
   * 订单打印按钮
   * 
   * **/
  function handlePrint(data) {
    print("售票列表", processData(data), propertiesConfig.value);
  }
  const ticketDialog = ref(null);  // 售票对话框
  const ticketType = ref(null);  // 售票类型：1.单个售票  2.团体售票
  const visitorList = ref([]);  // 游客填写信息
  const ticketInfo = ref({});  // 基本数据
  const formRef = ref(null);  // 表单对象
  const formRules = ref();
  const formData = ref({
    num: "",
    payway: null,
    timeArr: [],
    yh_money: 0,
    coupon_money: 0,
    ar_id: "",
    ar_tourist: "",
    ar_remark: "",
    remark: "",  // 备注
  });
  const commonFormRules = {
    num: [
      {
        required: true,
        message: "请输入售票数量",
        trigger: "blur",
      },
    ],
    payway: [
      {
        required: true,
        message: "请选择支付方式",
        trigger: "change",
      },
    ],
  };
  const orderTypeOptions = ref([]);  // 支付方式选项
  const companyOptions = ref([]);  // AR账户选项
  const faceLoading = ref(false);  // 加载状态
  const ticketCode = ref(false);  // 是否使用纸质票
  const nameIndex = ref(null);
  const nextIndex = ref(null);
  const nameRef = ref("");
  const faceDialogRef = ref(null);
  const currentCameraIndex = ref(null);
  const lastPayway = ref(null);
  const loadingDialog = ref(null);
  const succesDialog = ref(null);
  const errorDialog = ref(null);
  const barCodeInputRef = ref(null);
  const barCodeInput = ref("");
  const errorTitle = ref("订单支付失败");
  const lastOrderSn = ref("");
  /**
   * 
   * 获取支付方式选项
   * 
   * **/
  const getOrderType = () => {
    ScenicApi.getOrderType().then((res) => {
      if (res.Code === 200) {
        orderTypeOptions.value = res.Data;
      } else {
        ElMessage.error(res.Message);
      }
    });
  };
  /**
   * 
   * 获取AR账户选项
   * 
   * **/
  function changeRadio(v) {
    if (v == 7) {
      ScenicApi.accountOptions().then((res) => {
        if (res.Code === 200) {
          companyOptions.value = res.Data;
        } else {
          ElMessage.error(res.Message);
        }
      });
    }
  }
  function uploadLoading(flag) {
    faceLoading.value = flag;
  }
  /**
   * 
   * 图片上传
   * 
   * **/
  const uploadFile = (obj, index) => {
    faceLoading.value = false;
    visitorList.value[index].img = [{ file_url: obj.standard_url }];
    visitorList.value[index].file_key = obj.key;
  };
  function openFaceDialog(index) {
    currentCameraIndex.value = index;
    faceDialogRef.value.openDialog();
  }
  /**
   * 
   * 图片删除
   * 
   * **/
  const deleteFile = (obj, index) => {
    visitorList.value[index].img = [];
    visitorList.value[index].file_key = "";
  };
  /**
   * 
   * 删除游客信息
   * 
   * **/
  function deleteVisitor(index) {
    if (ticketType.value == 1) {
      formData.value.num == 1
        ? (formData.value.num = null)
        : (formData.value.num = +formData.value.num - 1);
    }
    visitorList.value.splice(index, 1);

    // 如果没有游客信息，则清空售票数量
    if (visitorList.value.length == 0) {
      formData.value.num = '';
    }
  }
  /**
   * 
   * 售票按钮
   * 
   * **/
  const saleTickets = (row) => {
    ticketType.value = 1;
    setTimeout(() => {
      getTicketInfo(row);
    }, 200);
  };
  /**
   * 
   * 团体售票按钮
   * 
   * **/
  const groupSaleTickets = (row) => {
    ticketType.value = 2;
    setTimeout(() => {
      getTicketInfo(row);
    }, 200);
  };
  /**
   * 
   * 获取基本数据
   * 
   * **/
  function getTicketInfo(row) {
    store.dispatch("getQiniuData");
    visitorList.value = [];
    const yhMoneyRule =
      ticketType.value == 2
        ? {
          yh_money: [
            {
              required: true,
              message: "请输入优惠金额",
              trigger: "blur",
            },
          ],
        }
        : {
          coupon_money: [
            {
              required: true,
              message: "请输入代金券抵扣金额",
              trigger: "blur",
            },
          ],
        };

    ScenicApi.ticketInfo({ st_id: row.id }).then((res) => {
      if (res.Code === 200) {
        ticketInfo.value = res.Data;
        formData.value = {
          num: "",
          yh_money: 0,
          coupon_money: 0,
          payway: null,
          ar_id: "",
          ar_tourist: "",
          ar_remark: "",
          remark: "",
        };

        if (ticketInfo.value.st_sttid == 2) {
          formRules.value = {
            ...commonFormRules,
            ...yhMoneyRule,
          };
          formData.value["timeArr"] = [
            dayjs().format("YYYY-MM-DD"),
            dayjs()
              .add(+ticketInfo.value.st_use_days - 1, "day")
              .format("YYYY-MM-DD"),
          ];
        } else {
          const currentDay = dayjs().unix();
          const beginDay = dayjs(ticketInfo.value.st_use_stime).unix();

          if (currentDay < beginDay) {
            formData.value["timeArr"] = [
              dayjs(ticketInfo.value.st_use_stime).format("YYYY-MM-DD"),
              dayjs(ticketInfo.value.st_use_stime).format("YYYY-MM-DD"),
            ];
          } else {
            formData.value["timeArr"] = [
              dayjs().format("YYYY-MM-DD"),
              dayjs().format("YYYY-MM-DD"),
            ];
          }
          formRules.value = {
            ...commonFormRules,
            ...yhMoneyRule,
            timeArr: [
              {
                required: true,
                message: "请输入使用期限",
                trigger: "blur",
              },
            ],
          };
        }
        visitorList.value = [];
        if (ws.value) {
          ws.value.close();
        }
        nameIndex.value = null;
        nameRef.value = "";
        nextIndex.value = null;
        ticketCode.value = false;
        ticketDialog.value.show();
        getIdentify();
      } else {
        ElMessage.error(res.Message);
      }
    });
  }
  /**
   * 
   * 是否编辑日期
   * 
   * **/
  function disabledDate(current) {
    if (ticketInfo.value.st_sttid != 2) {
      return (
        dayjs(current).unix() < dayjs(ticketInfo.value.st_use_stime).unix() ||
        dayjs(current).unix() > dayjs(ticketInfo.value.st_use_etime).unix()
      );
    }
  }
  function objectValueAllEmpty(object) {
    let isEmpty = true;
    Object.keys(object).forEach(function (x) {
      if (object[x] != null && object[x] != "") {
        isEmpty = false;
      }
    });
    if (isEmpty) {
      //值全为空
      return true;
    }
    return false;
  }
  /**
   * 
   * 输入售票数量
   * 
   * **/
  function onInputBlur() {
    visitorList.value = [];

    if (formData.value.num >= 1) {
      if (ticketType.value == 1) {
        for (var i = 0; i < +formData.value.num; i++) {
          const arr = JSON.parse(JSON.stringify(visitorObj.value));
          visitorList.value.push(arr);
        }
      } else {
        // 团体售票默认只显示一个游客信息
        visitorList.value.push(visitorObj.value);
      }
    }
  }
  const visitorObj = ref({
    m_realname: "",
    m_mobile: "",
    m_idcard: "",
    paper_sn: "",
    img: [],
  });
  function handleFocus(data, index, index2) {
    // 聚焦状态
    nameRef.value = data;
    nameIndex.value = index;
    nextIndex.value = index2;
  }
  function handleSn(data, index, index2) {
    const visitorSnRef = document.getElementById(data + index); // 当前名字框
    const nextVisitorSnRef = document.getElementById(data + index2); // 下一个名字框
    if (!visitorList.value[index]["m_realname"]) {
      visitorSnRef && visitorSnRef.focus();
    } else {
      nextVisitorSnRef && nextVisitorSnRef.focus();
    }
  }
  function hex2a(hex) {
    let str_list = "";
    for (let i = 0; i < hex.length && hex.substr(i, 2) !== "00"; i += 2) {
      const a = hex.charCodeAt(i);
      const b = hex.charCodeAt(i + 1);
      const c = b * 256 + a;
      str_list += String.fromCharCode(c);
    }
    return str_list.toString();
  }
  // 识别身份
  const connectAddress = ref("127.0.0.1:30004");
  const ws = ref(null);
  function getIdentify() {
    const webUrl = "ws://" + connectAddress.value + "/ws";
    ws.value = new WebSocket(webUrl);

    // ws.value.onopen = function () {
    // let szhelp =
    //   "websocket连接成功，url[" +
    //   webUrl +
    //   "]，读卡器上放置身份证后websocket会自动接收身份证数据，如需手动操作请调用WS_ReadInfo()函数\r\n\r\n";
    // szhelp += "支持被动接收和主动请求两种方式\r\n";
    // szhelp +=
    //   "被动接收:当读卡器刷卡成功后会推送身份证信息到websocket，websocket直接显示即可\r\n";
    // szhelp +=
    //   "主动请求:支持网页端主动向服务器请求对应的消息。可查看<WS_ReadInfo><WS_GetASN><WS_GetBCardNo>这三个接口";
    // console.log(szhelp);
    // };
    ws.value.onclose = function () {
      console.log("websocket已断开");
    };
    ws.value.onmessage = function (messageEvent) {
      const jsonobject = JSON.parse(messageEvent.data);
      if (jsonobject.Ret == 0) {
        if (jsonobject.Cmd == 10001) {
          // cleanMsg(index);
          const szparam = JSON.parse(window.atob(jsonobject.UserParam));

          const name = hex2a(window.atob(szparam.CardInfo.Name));
          const m_realname = name.replace(/ /g, "");
          const m_idcard = hex2a(window.atob(szparam.CardInfo.No));

          if (
            document.getElementById(nameRef.value + nameIndex.value) ==
            document.activeElement
          ) {
            visitorList.value[nameIndex.value].m_realname = m_realname;
            visitorList.value[nameIndex.value].m_idcard = m_idcard;

            if (ticketCode.value) {
              if (!visitorList.value[nameIndex.value]["paper_sn"]) {
                const visitorSnRef = document.getElementById(
                  "visitorSn" + nameIndex.value
                );
                visitorSnRef.focus();
              } else {
                const visitorNameRef = document.getElementById(
                  nameRef.value + nextIndex.value
                );
                if (visitorNameRef) {
                  visitorNameRef.focus();
                }
              }
            }

            if (!ticketCode.value) {
              // 周期票不用跳到下一个 要上传人脸
              if ([1, 3].includes(ticketInfo.value.st_sttid)) {
                const visitorNameRef = document.getElementById(
                  nameRef.value + nextIndex.value
                );
                if (visitorNameRef) {
                  visitorNameRef.focus();
                }
              }
            }
          }
        }
      } else if (jsonobject.Cmd == 30401) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
      } else if (jsonobject.Cmd == 20401) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
      } else if (jsonobject.Cmd == 20511) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
      } else if (jsonobject.Cmd == 1000) {
        const szparam = JSON.parse(window.atob(jsonobject.UserParam));
        if (szparam.State == 0) {
          console.log("读卡器已被拔出");
        } else {
          console.log("读卡器已插入");
        }
      } else {
        // console.log("websocket 协议调用失败，原因：" + jsonobject.ErrInfo);
      }
    };
  }
  function getOrderInfo(orderSnParmas) {
    ScenicApi.orderInfo(orderSnParmas).then((res) => {
      if (res.Code == 200) {
        lastPayway.value = res.Data.payway_name;
        if (res.Data.or_pay_status == 2) {
          //支付成功
          succesDialog.value.show();
          loadingDialog.value.close();
        } else if (res.Data.or_pay_status == 1) {
          nextTick(() => {
            barCodeInputRef.value.focus();
          });
          lastOrderSn.value = res.Data.or_sn;
          // timer.value = window.setInterval(() => {
          //   setTimeout(() => {
          //     barCodeInfo({ order_sn: res.Data.or_sn });
          //   }, 0);
          // }, 3000);
        }
      }
    });
  }
  function barCodeInfo() {
    const lastParmas = {
      order_sn: lastOrderSn.value,
      auth_code: barCodeInput.value,
    };
    barCodeInput.value &&
      ScenicApi.barCodeInfo(lastParmas).then((res) => {
        if (res.Code == 200) {
          if (res.Data.Code == 200) {
            barCodeInput.value = "";
            succesDialog.value.show();
            loadingDialog.value.close();
            lastOrderSn.value = "";
          } else if (res.Data.Code == 205) {
            setTimeout(() => {
              barCodeInfo();
            }, 3000);
          } else {
            barCodeInput.value = "";
            errorTitle.value = res.Data.Message || "订单支付失败";
            loadingDialog.value.close();
            errorDialog.value.show();
            lastOrderSn.value = "";
          }
        } else {
          loadingDialog.value.close();
          errorDialog.value.show();
          lastOrderSn.value = "";
        }
      });
  }
  /**
   * 
   * 售票确定按钮
   * 
   * **/
  function confirm() {
    if (formRef.value) {
      formRef.value.validate((valid) => {
        if (valid) {
          lastPayway.value = null;
          if (!visitorList.value.length) {
            ElMessage.warning("请录入游客信息！");
            return false;
          } else {
            let buy_user = [];

            // st_sttid 1-常规票 2-周期性票 3-活动常规票 4-性票
            // 1【不需要录入人脸】【票码非必填】
            // 【使用期限 a、当天在返回的时间范围之后 那么默认显示当天  b、当天在返回范围之内 那么默认显示当天  c、当天在返回之前 默认显示返回的第一天 选择范围都是后端返回的开始结束时间范围】
            // 2【需要录入人脸】【不需要票码】【使用期限 选择范围是当天 --> st_use_days - 1】
            // 3【不需要录入人脸】【票码非必填】【使用期限同1】
            // 4【需要录入人脸】【不需要票码】【使用期限同1】

            if (ticketType.value == 1) {
              // 线下售票
              for (var i = 0; i < visitorList.value.length; i++) {
                if (!visitorList.value[i].m_realname) {
                  ElMessage.warning("请将游客的姓名补充完整！");
                  return false;
                } else if (
                  !checkStr(visitorList.value[i].m_realname, "chinese")
                ) {
                  ElMessage.warning(`请输入正确的游客姓名！`);
                  visitorList.value[i].m_realname = "";

                  return false;
                }
                if (visitorList.value[i].m_mobile) {
                  if (!checkStr(visitorList.value[i].m_mobile, "phone")) {
                    ElMessage.warning(
                      `游客${visitorList.value[i].m_realname}的手机号格式错误！`
                    );
                    visitorList.value[i].m_mobile = "";
                    return false;
                  }
                }

                if (visitorList.value[i].m_idcard) {
                  if (!checkStr(visitorList.value[i].m_idcard, "card")) {
                    ElMessage.warning(
                      `游客${visitorList.value[i].m_realname}的身份证格式错误！`
                    );
                    visitorList.value[i].m_idcard = "";
                    return false;
                  }
                }

                if (
                  [2, 4].includes(ticketInfo.value.st_sttid) &&
                  !visitorList.value[i].file_key
                ) {
                  ElMessage.warning(
                    `游客${visitorList.value[i].m_realname}的人脸未录入！`
                  );
                  return false;
                }
                if ([1, 3].includes(ticketInfo.value.st_sttid)) {
                  buy_user.push({
                    m_idcard: visitorList.value[i].m_idcard,
                    m_mobile: visitorList.value[i].m_mobile,
                    m_realname: visitorList.value[i].m_realname,
                    paper_sn: visitorList.value[i].paper_sn,
                  });
                } else {
                  buy_user.push({
                    m_idcard: visitorList.value[i].m_idcard,
                    m_mobile: visitorList.value[i].m_mobile,
                    m_realname: visitorList.value[i].m_realname,
                    file_key: visitorList.value[i].file_key,
                  });
                }
              }
            } else {
              //团体
              for (var j = 0; j < visitorList.value.length; j++) {
                if ([1, 3].includes(ticketInfo.value.st_sttid)) {
                  if (!objectValueAllEmpty(visitorList.value[j])) {
                    buy_user.push({
                      m_idcard: visitorList.value[j].m_idcard,
                      m_mobile: visitorList.value[j].m_mobile,
                      m_realname: visitorList.value[j].m_realname,
                      paper_sn: visitorList.value[j].paper_sn,
                    });
                  }
                } else {
                  if (!objectValueAllEmpty(visitorList.value[j])) {
                    buy_user.push({
                      m_idcard: visitorList.value[j].m_idcard,
                      m_mobile: visitorList.value[j].m_mobile,
                      m_realname: visitorList.value[j].m_realname,
                      file_key: visitorList.value[j].file_key,
                    });
                  }
                }
              }
              for (let k = 0; k < buy_user.length; k++) {
                if (
                  (!buy_user[k].m_realname && buy_user[k].m_mobile) ||
                  (!buy_user[k].m_realname && buy_user[k].m_idcard)
                ) {
                  ElMessage.warning("请将所填游客的姓名补充完整！");
                  return false;
                } else if (!checkStr(buy_user[k].m_realname, "chinese")) {
                  ElMessage.warning(`请输入正确的游客姓名！`);
                  return false;
                }
                if (!buy_user[k].m_mobile) {
                  ElMessage.warning("请将所填游客的手机号码补充完整！");
                  return false;
                }
                if (buy_user[k].m_mobile) {
                  if (!checkStr(buy_user[k].m_mobile, "phone")) {
                    ElMessage.warning(`游客的手机号格式错误！`);
                    return false;
                  }
                }
                if (buy_user[k].m_idcard) {
                  if (!checkStr(buy_user[k].m_idcard, "card")) {
                    ElMessage.warning(`游客的身份证格式错误！`);
                    return false;
                  }
                }
              }
            }
            if (formData.value.payway == 7 && !formData.value.ar_id) {
              ElMessage.warning(`请选择AR账户！`);
              return false;
            }
            if (formData.value.payway == 7 && !formData.value.ar_tourist) {
              ElMessage.warning(`请输入AR挂账的游客姓名！`);
              return false;
            }
            const parmas = {
              stid: ticketInfo.value.st_id,
              sid: ticketInfo.value.st_sid,
              payway: formData.value.payway,
              num: formData.value.num,
              buy_user: buy_user,
              remark: formData.value.remark,
            };
            ticketType.value == 2
              ? (parmas["yh_money"] = formData.value.yh_money)
              : (parmas["coupon_money"] = formData.value.coupon_money);
            if (ticketInfo.value.st_sttid != 2) {
              parmas["st_use_stime"] = formData.value.timeArr[0];
              parmas["st_use_etime"] = formData.value.timeArr[1];
            }
            if (formData.value.payway == 7) {
              parmas["ar_id"] = formData.value.ar_id;
              parmas["ar_tourist"] = formData.value.ar_tourist;
              parmas["ar_remark"] = formData.value.ar_remark;
            }
            ticketDialog.value.isLoading = true;
            if (ticketType.value == 1) {
              HotelApi.offlineSellTicket(parmas).then((res) => {
                ticketDialog.value.isLoading = false;
                if (res.Code === 200) {
                  const orderSnParmas = {
                    order_sn: res.Data.or_sn,
                  };
                  loadingDialog.value.show();
                  setTimeout(() => {
                    getOrderInfo(orderSnParmas);
                  }, 2000);
                } else {
                  let msg = res.Message ? res.Message : `售票失败！`;
                  ElMessage.error(msg);
                }
              });
            } else {
              HotelApi.offlineSellGroupTicket(parmas).then((res) => {
                ticketDialog.value.isLoading = false;
                if (res.Code === 200) {
                  const orderSnParmas = {
                    order_sn: res.Data.or_sn,
                  };
                  loadingDialog.value.show();
                  setTimeout(() => {
                    getOrderInfo(orderSnParmas);
                  }, 2000);
                } else {
                  let msg = res.Message ? res.Message : `售票失败！`;
                  ElMessage.error(msg);
                }
              });
            }
          }
        }
      });
    }
  }
  function totalMoney() {
    let result = null;
    const price = ticketInfo.value.st_price || 0;
    const number = formData.value.num || 0;
    const yhMoney = formData.value.yh_money || 0;
    const coupon_money = formData.value.coupon_money || 0;

    if (ticketType.value == 1) {
      result = price * number - coupon_money;
      if (result < 0) {
        formData.value.coupon_money = 0;
        result = price * number;
      }
    } else {
      result = price * number - yhMoney;
      if (result < 0) {
        formData.value.yh_money = 0;
        result = price * number;
      }
    }

    return roundFractional(result, 2);
  }
  function roundFractional(x, n) {
    return Math.round(x * Math.pow(10, n)) / Math.pow(10, n);
  }
  function CloseStatusDialog() {
    lastOrderSn.value = "";
    barCodeInput.value = "";
    errorDialog.value.close();
    succesDialog.value.close();
    ticketDialog.value.close();
    refreshTable();
  }
  function CloseLoadingDialog() {
    lastOrderSn.value = "";
    barCodeInput.value = "";
    loadingDialog.value.close();
  }
  function CloseErrorDialog() {
    barCodeInput.value = "";
    lastOrderSn.value = "";

    errorDialog.value.close();
  }
</script>
<style lang="scss">
  .order-assoc {
    font-family: "Source Han Sans CN";

    .search-input {
      width: 310px !important;
    }

    .verification-dialog {
      .el-form-item {
        margin-bottom: 6px;
      }

      .el-form-item__error {
        display: none;
      }

      .tips {
        color: var(--theme-color);
        font-size: 12px;
      }
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .el-input-group__append {
      border: none;
      background-color: var(--search-uncheck-bg-color) !important;
      color: var(--text-gray-color);
    }

    .content {
      padding: 15px 20px 20px;
    }

    .check-time {
      margin-top: 10px;
      display: flex;
      justify-content: center;
    }

    .font-gray {
      color: var(--text-gray-color);
    }

    .font-999 {
      color: var(--text-third-color);
    }

    .refund-money {
      width: 100%;
      padding: 20px 0;
      background-color: var(--theme-bg-color);
      text-align: center;
      border-radius: 10px;
      margin-bottom: 20px;
      color: var(--text-gray-color);

      >div {
        color: var(--text-color);
        font-weight: bold;
        font-size: 16px;
      }
    }

    .ticket-dialog {
      .el-dialog__body {
        padding: 0;

        .ticket-content {
          height: 680px;
          display: flex;

          .right-content {
            width: 40%;
            border-left: 1px solid #eee;
            overflow-y: auto;
            padding: 20px;

            .el-empty__image {
              width: 100px;
            }

            .right-title {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin-bottom: 20px;

              .enter-title {
                color: var(--text-third-color);
                font-size: 16px;
              }

              .enter-switch {
                display: flex;
                align-items: center;

                >span {
                  color: var(--text-gray-color);
                  padding-right: 6px;
                }
              }
            }

            .visitor-item {
              border: 2px dashed #eee;
              padding: 10px 30px 10px 10px;
              margin-bottom: 20px;
              display: flex;
              justify-content: space-between;
              position: relative;

              .close-icon {
                position: absolute;
                right: 4px;
                top: 4px;

                >i {
                  font-size: 16px;
                  font-weight: bold;
                  cursor: pointer;

                  &:hover {
                    color: var(--theme-color);
                  }
                }
              }

              .upload-box {
                text-align: right;
                display: flex;
                align-items: center;

                .img-container {
                  .el-dropdown {
                    position: absolute;
                    color: var(--theme-color);
                    right: 16px;
                    bottom: 20px;
                    cursor: pointer;
                  }

                  .face-img {
                    position: relative;

                    .el-image {
                      width: 56px !important;
                      height: 56px !important;
                      border-radius: 6px;
                    }

                    .del {
                      position: absolute;
                      top: 0;
                      right: 0;
                      height: 13px;
                      width: 18px;
                      line-height: 13px;
                      color: #fff;
                      background-color: rgba(0, 0, 0, 0.5);
                      border-radius: 0 4px 0 4px;
                      text-align: center;
                      font-size: 12px;
                      cursor: pointer;
                    }
                  }
                }
              }

              .visitor-form {
                flex: 1;

                .el-form-item__content {
                  height: 20px;
                  line-height: 20px;
                }

                .el-form-item__label {
                  padding-right: 0;
                  user-select: none;
                }

                .el-input__inner {
                  padding-right: 10px;
                }

                .el-form-item {
                  width: 80%;
                  align-items: center;
                  margin-bottom: 8px;

                  .el-input__inner {
                    height: 20px;
                    line-height: 20px;
                  }

                  .el-form-item__error {
                    top: 68%;
                    left: 15px;
                  }
                }

                .el-form-item__content .el-input__inner,
                .el-form-item__content .el-textarea__inner {
                  background-color: transparent;
                }
              }
            }
          }

          .left-content {
            flex: 1;
            overflow-y: auto;
            padding: 20px;

            .basic-info {
              width: 100%;
              background-color: var(--theme-bg-color);
              padding: 10px;
              border-radius: 4px;

              .ticket-title {
                font-weight: 700;
                font-size: 16px;
              }

              .basic-item {
                margin-top: 10px;
                width: 100%;
                color: var(--text-third-color);

                >span {
                  color: var(--text-gray-color);
                }
              }
            }

            .ticket-form {
              margin-top: 20px;

              .total-money {
                color: var(--text-third-color);

                >span {
                  margin-left: 4px;
                  color: var(--text-color);
                  font-weight: bold;
                  font-size: 20px;
                }
              }

              .el-form-item__label {
                color: var(--text-third-color);
                padding-bottom: 4px;
              }
            }
          }
        }
      }

      .el-dialog__footer {
        padding: 30px;
        border-top: 1px solid #eee;
      }
    }
  }
</style>