<!-- 订单列表 -->

<template>
  <div class="order-lists-dialog">
    <w-dialog ref="dialogRef" title="订单列表" width="80%" top="5vh" :hideFooter="true">
      <common-table ref="tableRef" tableHeight="600" :apiName="HotelApi.getAssocSalesOrderList" :columns="tableColumns"
        :extraParame="extraParame" :filters="filters" :groupFilters="groupFilters" @onDetailBtn="onDetailBtn">
        <!-- 支付状态 -->
        <template #pay_status_text="{ row }">
          <div class="status-text" :class="orderStatusColors(row.pay_status, 'pay')">{{ row.pay_status_text }}</div>
        </template>

        <!-- 业务状态 -->
        <template #work_status_text="{ row }">
          <div class="status-text" :class="orderStatusColors(row.work_status, 'work')">{{ row.work_status_text }}</div>
        </template>

        <!-- 发起退款 -->
        <template #startrRefund="{ row }">
          <template v-if="row.is_init_refund == '1' && authData.indexOf('h_ZLY1B2aJ1VwOgDzYYGXUFjNIh58k') != -1">
            <el-button class="black-font-btn" @click="openStartrRefundDialog(row)">发起退款</el-button>
          </template>
        </template>

        <!-- 确认退款 -->
        <template #sureRefund="{ row }">
          <template v-if="row.is_confirm_refund == 1 && authData.indexOf('h_ETrMwWza4qPH3fEihBsTOHe0FhSX') != -1">
            <el-button class="theme-font-btn" @click="openSureRefundDialog(row)">确认退款</el-button>
          </template>
        </template>

        <!-- 取消退款 -->
        <template #cancelRefund="{ row }">
          <template v-if="row.is_cancel_refund == 1 && authData.indexOf('h_62aLxCxovfel0OuSQACgaGvDqN9J') != -1">
            <el-popconfirm title="确定要取消该订单退款吗?" @confirm="onConfirmRefund(row)">
              <template #reference>
                <el-button class="theme-font-btn">取消退款</el-button>
              </template>
            </el-popconfirm>
          </template>
        </template>

        <!-- 打印 -->
        <template #printTicket="{ row }">
          <template v-if="row.or_type == 2">
            <el-button class="theme-font-btn" @click="printTicket(row)">打印</el-button>
          </template>
        </template>

        <!-- 团体检票 -->
        <template #groupTicket="{ row }">
          <template v-if="authData.indexOf('h_uQYjgL3juQF3wsmA7a0Nroz3XZyo') != -1 && row.is_group_check_order == 1">
            <el-button @click="onGroupTicket(row)">团体检票</el-button>
          </template>
        </template>
      </common-table>
    </w-dialog>

    <!-- 门票订单详情 -->
    <AssocOrderTicketsDetail ref="ticketsDetailRef" @submit="refreshTable"></AssocOrderTicketsDetail>

    <!-- 发起退款 -->
    <RefundDialog ref="initRefundRef" @submit="handleInitRefund"></RefundDialog>

    <!-- 确认退款 -->
    <RefundDialog ref="sureRefundRef" @submit="handleSureRefund"></RefundDialog>

    <!-- 团体检票 -->
    <GroupTicketInspec ref="groupTicket" @submit="onConfirmVerification"></GroupTicketInspec>

    <!-- 打印 -->
    <PrintOrder ref="printOrderRef"></PrintOrder>
  </div>
</template>

<script>
  import { ref, onMounted, nextTick, computed, watch, } from "vue";
  import { HotelApi, BasicApi, } from "@/plugins/api.js";
  import PrintOrder from "./PrintOrder.vue";
  import AssocOrderTicketsDetail from "./AssocOrderTicketsDetail.vue";
  import GroupTicketInspec from "./GroupTicketInspec.vue";
  import RefundDialog from "../../order/components/RefundDialog.vue";
  import {
    getPayStatusOptions,
    getAssocWorkStatusOptions,
    orderStatusColors,
  } from "@/utils/common.js";
  import { useStore } from "vuex";
  import { ElMessage } from "element-plus";

  export default {
    emits: ["submit"],
    components: {
      AssocOrderTicketsDetail,
      GroupTicketInspec,
      RefundDialog,
      PrintOrder,
    },
    setup(props, { emit }) {
      const store = useStore();
      const menuTokens = computed(() => store.state.menuToken.menuTokens);
      const authData = ref([]);
      watch(
        () => menuTokens.value,
        (data) => {
          if (data.length) {
            authData.value = data;
          }
        },
        {
          deep: true,
          immediate: true,
        }
      );
      const dialogRef = ref(null);  // 弹框对象
      const tableRef = ref(null);  // 表格对象
      const extraParame = ref({
        order_type: '2',
        id: '',
      });  // 自定义参数
      const tableColumns = ref([  // 表格配置
        {
          prop: "sn",
          label: "订单号",
          minWidth: 220,
        },
        {
          prop: "assoc_name",
          label: "套票名称",
          minWidth: 240,
        },
        {
          prop: "buy_num",
          label: "购买数量",
        },
        {
          prop: "refund_num",
          label: "退款数量",
        },
        {
          prop: "pay_money",
          label: "支付金额",
        },
        {
          prop: "refund_money",
          label: "退款金额",
        },
        {
          prop: "yh_money",
          label: "优惠金额",
        },
        {
          prop: "coupon_money",
          label: "代金券",
        },
        {
          prop: "agent_name",
          label: "分销商",
        },
        {
          prop: "source_text",
          label: "来源渠道",
        },
        {
          prop: "payway_name",
          label: "支付方式",
        },
        {
          prop: "pay_status_text",
          label: "支付状态",
          type: "customRender",
        },
        {
          prop: "work_status_text",
          label: "业务状态",
          type: "customRender",
        },
        {
          prop: "pay_time",
          label: "支付时间",
          minWidth: 170,
        },
        {
          type: "operation",
          prop: "",
          label: "操作",
          minWidth: 380,
          bottons: [
            {
              name: "详情",
              action: "onDetailBtn",
              token: "h_dz3rIWTFMUE35Tk0iDXR1jnmdoVE",
            },
            {
              name: "打印",
              action: "printTicket",
              type: "customRender",
            },
            {
              name: "发起退款",
              action: "startrRefund",
              type: "customRender",
            },
            {
              name: "确认退款",
              action: "sureRefund",
              type: "customRender",
            },
            {
              name: "取消退款",
              action: "cancelRefund",
              type: "customRender",
            },
            {
              name: "团体检票",
              action: "groupTicket",
              type: "customRender",
            },
          ],
        },
      ]);
      const filters = ref([
        {
          filterType: "search",
          name: "keyword",
          value: "",
          placeholder: "搜索订单号、套票名称、游客姓名、手机号",
        },
      ]);
      const groupFilters = ref([
        {
          filterType: "select",
          name: "source",
          value: "",
          label: "购买渠道",
          options: [],
          val: "id",
          lab: "name",
        },
        {
          filterType: "select",
          name: "pay_status",
          value: '2',
          label: "支付状态",
          options: [],
          val: "value",
          lab: "label",
          otherOptionsName: "work_status",
          showOtherOptionsValue: 2,
        },
        {
          filterType: "multipleSelect",
          name: "work_status",
          value: [],
          label: "业务状态",
          options: [],
          val: "value",
          lab: "label",
        },
        {
          filterType: "partDaterange",
          name1: "stime",
          value1: "",
          name2: "etime",
          value2: "",
          label: "支付时间",
        },
      ]);
      const ticketsDetailRef = ref(null);  // 订单详情对象
      const printOrderRef = ref(null);

      /**
       * 
       * 打开对话框
       * 
       * **/
      const openDialog = (row) => {
        extraParame.value.id = row.id;
        dialogRef.value.show();
        nextTick(() => {
          tableRef.value.tableLoad();
        });
      }
      /**
       * 
       * 关闭对话框
       * 
       * **/
      const closeDialog = () => {
        dialogRef.value.close();
      }
      /**
       * 
       * 打开对话框加载状态
       * 
       * **/
      const closeDialogLoading = () => {
        dialogRef.value.isLoading = false;
      }
      /**
       * 
       * 详情按钮
       * 
       * **/
      const onDetailBtn = (row) => {
        if (row.or_type == 2) {
          // 门票
          ticketsDetailRef.value.openDialog(row);
        }
      }
      /**
       * 
       * 订单列表打印按钮
       * 
       * **/
      const printTicket = (row) => {
        printOrderRef.value.openDialog(row.sn);
      }
      /**
       * 
       * 重新获取数据
       * 
       * **/
      const refreshTable = () => {
        tableRef.value.tableLoad();
      }
      const payStatusOptions = ref(getPayStatusOptions());
      const workStatusOptions = ref(getAssocWorkStatusOptions());
      const payStatusLabelArr = ref([]);
      function getPayStatusLabelArr() {
        for (var key in payStatusOptions.value) {
          payStatusOptions.value[key] &&
            payStatusLabelArr.value.push({
              label: payStatusOptions.value[key],
              value: +key,
            });
        }
        groupFilters.value[1].options = payStatusLabelArr.value;
      }
      const workStatusLabelArr = ref([]);
      function getWorkStatusLabelArr() {
        for (var key in workStatusOptions.value) {
          workStatusOptions.value[key] &&
            workStatusLabelArr.value.push({
              label: workStatusOptions.value[key],
              value: +key,
            });
        }
        groupFilters.value[2].options = workStatusLabelArr.value;
      }
      /**
       * 
       * 筛选条件-获取购买渠道选项
       * 
       * **/
      const getSourceOptions = () => {
        BasicApi.orderSourceOptions().then((res) => {
          if (res.Code === 200) {
            groupFilters.value[0].options = res.Data;
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      const initRefundRef = ref(null);  // 发起退款对象
      const currentRefundSn = ref("");  // 发起退款id
      /**
       * 
       * 退款按钮
       * 
       * **/
      const openStartrRefundDialog = (row) => {
        currentRefundSn.value = row.sn;
        initRefundRef.value.openDialog("发起退款", row.surplus_money);
      };
      /**
       * 
       * 退款确定按钮
       * 
       * **/
      const handleInitRefund = (data) => {
        const parmas = {
          sn: currentRefundSn.value,
          ...data,
        };
        HotelApi.initiateRefundOrder(parmas).then((res) => {
          initRefundRef.value.closeDialogLoading();
          if (res.Code === 200) {
            initRefundRef.value.closeDialog();
            refreshTable();
            ElMessage.success("操作成功");
            emit("submit");
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      const groupTicket = ref(null);  // // 团体检票对话框
      /**
        *
        * 团体检票按钮
        *
        **/
      const onGroupTicket = (row) => {
        groupTicket.value.openDialog(row.sn);
      }
      /**
        *
        * 团体检票确认核销按钮
        *
        **/
      const onConfirmVerification = (data) => {
        HotelApi.checkGroupTicket(data).then((res) => {
          groupTicket.value.closeDialogLoading();
          if (res.Code === 200) {
            groupTicket.value.closeDialog();
            tableRef.value.tableLoad();
            ElMessage.success("操作成功");
            emit("submit");
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
        *
        * 确认退款按钮
        *
        **/
      const sureRefundRef = ref(null); // 退款对话框
      const openSureRefundDialog = (row) => {
        currentRefundSn.value = row.sn;
        sureRefundRef.value.openDialog(
          "确认退款",
          row.pay_money,
          row.confirm_refund_money
        );
      }
      /**
        *
        * 确认退款确定按钮
        *
        **/
      const handleSureRefund = (data) => {
        const parmas = {
          sn: currentRefundSn.value,
          ...data,
        };
        HotelApi.confirmRefundOrder(parmas).then((res) => {
          sureRefundRef.value.closeDialogLoading();
          if (res.Code === 200) {
            sureRefundRef.value.closeDialog();
            refreshTable();
            ElMessage.success("操作成功");
            emit("submit");
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      /**
       * 
       * 取消退款确定按钮
       * 
       * **/
      const onConfirmRefund = (row) => {
        HotelApi.cancelRefundOrder({ sn: row.sn }).then((res) => {
          if (res.Code === 200) {
            ElMessage.success("取消退款成功！");
            refreshTable();
            emit("submit");
          } else {
            ElMessage.error(res.Message);
          }
        });
      }
      onMounted(() => {
        getPayStatusLabelArr();
        getWorkStatusLabelArr();
        getSourceOptions();
      });

      return {
        authData,
        openDialog,
        dialogRef,
        closeDialog,
        closeDialogLoading,
        HotelApi,
        tableColumns,
        tableRef,
        extraParame,
        orderStatusColors,
        printOrderRef,
        printTicket,
        ticketsDetailRef,
        onDetailBtn,
        refreshTable,
        handleInitRefund,
        groupTicket,
        initRefundRef,
        currentRefundSn,
        openStartrRefundDialog,
        onGroupTicket,
        onConfirmVerification,
        sureRefundRef,
        handleSureRefund,
        onConfirmRefund,
        filters,
        groupFilters,
        payStatusOptions,
        workStatusOptions,
        payStatusLabelArr,
        getPayStatusLabelArr,
        workStatusLabelArr,
        getWorkStatusLabelArr,
        getSourceOptions,
        openSureRefundDialog,
      };
    },
  };
</script>

<style lang="scss">
  .order-lists-dialog {}
</style>